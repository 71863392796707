<template lang="pug">
	v-container(fill-height align="center"  justify="center")
		//- v-row( align="center"  justify="center" )
		//- 	v-col( cols="12")
		//- 		.top(class="pt-5")
		//- 		div(class="d-flex justify-center")
		//- 	v-col( cols="12" sm="10" lg="7")
		v-row( align="center"  justify="center" )
			v-col( cols="12" sm="6" lg="5" class="text-center")
				//- v-img(src="/imgs/logo.svg"  max-width="100" class="ml-3")
				span(class="display-2 mb-10 blue-500--text") Analítica Coppel Omnicanal
				v-spacer
				v-spacer
				v-icon(size="150" color="blue-400" class="mt-10") mdi-chart-bell-curve-cumulative
			v-col( cols="12" sm="6" lg="5")
				v-card( class="elevation-12")
					v-card-text
						v-form
							h2(class="title  font-weight-bold black--text text-center mb-10") Inicia sesión con tu usuario
							v-text-field( prepend-icon="mdi-account" placeholder=" " label="Usuario" type="text" v-model="username")
							//- transition(name="slide-fade" mode="out-in")
							//- 	template(v-if="errors.email")
							//- 		p(v-for="item of errors.email" class="red--text") <b> {{item}}</b>

							v-text-field( prepend-icon="mdi-lock"  placeholder=" " label="Contraseña" type="password" v-model="password")
							transition(name="slide-fade" mode="out-in")
								template(v-if="errors.password")
									p(v-for="item of errors.password" class="red--text") <b> {{item}}</b>
							//- v-switch(v-model="remember"  :label="$t('auth.remember')"  color="ui-200")
							//- a( class="text-xs-right d-block" outline  color="primary" href="/password/reset") {{ $t('auth.forgot') }}
							div(align="center"  justify="center")
								a(@click="goResetPassword()") ¿Olvidaste tu contraseña?
					v-card-actions
						v-spacer
						v-btn( color="blue-500" 
							class="white--text text-none"
							:loading="loading"
							:disabled="loading"
							@click="loginAxios()" ) Iniciar sesión
</template>
<script>
	import DataLayersMixin from '@/mixins/DataLayersMixin'
	// var axios = require('axios');
	import Users from '../services/Users'
	const users = new Users();

	export default {
		props: {
			routeForm: String
		},
		mixins: [DataLayersMixin],
		data () {
			return {
				loading		: false,
				
				username	: '',
				password	: '',
				remember  	: true,

				errors 		: {},
				showError 	: false,
			}
		},
		methods: {
			goResetPassword() {
				this.dataLayerClickBtnOlvidasteContrasena();
				this.$router.push({name: 'reset_password'});
			},
			loginAxios:function(){
				this.errors = {};
				let  data = {
					username	: this.username,
					password	: this.password,
				};
				this.loading = true;
				// send datalayer
				this.dataLayerClickBtnIniciarSesion()
				// send request
				users.login(data)
				.then( (response)=>{
					let data = response.data;
					localStorage.setItem('access', data.access)
					localStorage.setItem('refresh', data.refresh)
					this.$store.dispatch('session/session', { token: data.access, refresh: data.refresh })
					
					var now = new Date();
					now.setMinutes(now.getMinutes() + 5)
					let time = new Date(now).getTime();

					localStorage.setItem('time', time)

					// dataLayer event
					this.dataLayerEventInicioSesionExito()
					// 
					this.$router.push({name: 'panel'})

					// this.$store.dispatch('setUser', {
					// 	id: data.id,
					// 	name: data.first_name,
					// 	last_name: data.last_name,
					// 	lang: data.lenguaje,
					// 	email: data.email,
					// });

					// if(response.status == 200){
					// 	location.reload();
					// }
				})
				.catch(error => {
					this.loading = false;
					this.errors = {"password":[
						"Tus credenciales son incorrectas"
					]};
					//this.showError 	= true;
					//this.isDisabled = false;
				})
			}
		},
		mounted() {
			
			// send datalayer
			this.dataLayerLoadedPageLogin();
		}
	}
</script>